<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: octubre 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <v-main style="padding: 0 1rem !important;">

        <!-- filtros de busqueda y botón para agregar un producto -->
        <div class="d-flex pt-3">
            <v-text-field class="me-2" outlined dense hide-details label="Código"
                v-model="buscadorCodigo" @keypress="validarLetraBusqueda" @paste.prevent @input="listarProductos()">
            </v-text-field>

            <v-text-field class="me-2" outlined dense hide-details label="Nombre"
                v-model="buscadorNombre" @input="listarProductos()">
            </v-text-field>

            <v-text-field class="me-2" outlined dense hide-details label="Principio activo"
                v-model="buscadorPrincipioActivo" @input="listarProductos()">
            </v-text-field>

            <v-text-field class="me-2" outlined dense hide-details label="Forma farmacéutica"
                v-model="buscadorFormaFarmaceutica" @input="listarProductos()">
            </v-text-field>

            <v-text-field class="me-2" outlined dense hide-details label="Concentración"
                v-model="buscadorConcentracion" @input="listarProductos()">
            </v-text-field>

            <v-tooltip left color="success">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small class="ms-2" fab color="success" v-bind="attrs" v-on="on" 
                        @mousedown.prevent="dialog = true">
                        <v-icon> add </v-icon>
                    </v-btn>
                </template>
                <span>Agregar</span>
            </v-tooltip>
        </div>

        <!-- dialogo para crear y actualizar un producto -->
        <v-dialog v-model="dialog" :editar="false" persistent transition="dialog-bottom-transition" max-width="38.5rem">
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">{{ tituloDialog }}</span>
                </v-card-title>

                <!-- formulario para crear y editar un producto -->
                <v-card-text style="padding-top: 0.7rem;">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <v-form class="mt-3" lazy-validation>

                            <!-- Campos para el código, el código Atcs y definir la concentración del producto -->
                            <div class="d-flex mt-3 formulario-flex">
                                <div class="d-flex align-items-center me-2">
                                    <validation-provider v-slot="{ errors }" :rules="{ required: true, }">
                                        <v-text-field outlined id="codigoProducto" v-model="nuevoProducto.codigoProducto"
                                            dense :error-messages="validarExistencia || errors" style="width: 11.5rem" label="Código" 
                                            @keypress="validarLetra" @paste.prevent :disabled="disabled == 1">
                                        </v-text-field>
                                    </validation-provider>
                                </div>
                                <div class="d-flex align-items-center me-2">
                                    <validation-provider v-slot="{ errors }" :rules="{ required: true, }">
                                        <v-text-field outlined id="codigoAtc" v-model="nuevoProducto.codigoAtc"
                                            dense :error-messages="errors" style="width: 11.5rem" label="Código ATC" 
                                            @input="convertirAMayusculas('codigoAtc')" :disabled="cambioDedatos || nuevoProducto.codigoAtc == '' || disabled == 1">
                                        </v-text-field>
                                    </validation-provider>
                                </div>
                                <div class="d-flex align-items-center">
                                    <validation-provider v-slot="{ errors }" :rules="{ required: true, }">
                                        <v-text-field outlined id="concentracion" v-model="nuevoProducto.concentracion"
                                            dense :error-messages="errors" style="width: 11.5rem" label="Concentración"
                                            @input="convertirAMayusculas('concentracion')" :disabled="cambioDedatos || nuevoProducto.concentracion == '' || disabled == 1">
                                        </v-text-field>
                                    </validation-provider>
                                </div>
                            </div>

                            <!-- Campos para definir el principio activo y la forma del fármaco del producto -->
                            <div class="d-flex formulario-flex">
                                <div class="d-flex align-items-center me-2">
                                    <validation-provider v-slot="{ errors }" :rules="{ required: true, }">
                                        <v-text-field outlined id="principioActivo" v-model="nuevoProducto.principioActivo"
                                            dense :error-messages="errors" style="width: 17.5rem" label="Principio activo"
                                            @input="convertirAMayusculas('principioActivo')" :disabled="cambioDedatos || nuevoProducto.principioActivo == '' || disabled == 1">
                                        </v-text-field>
                                    </validation-provider>
                                </div>
                                <div class="d-flex align-items-center">
                                    <validation-provider v-slot="{ errors }" :rules="{ required: true, }">
                                        <v-text-field outlined id="formaFarmaceutica" v-model="nuevoProducto.formaFarmaceutica"
                                            dense :error-messages="errors" style="width: 17.5rem" label="Forma farmacéutica"
                                            @input="convertirAMayusculas('formaFarmaceutica')" :disabled="cambioDedatos || nuevoProducto.formaFarmaceutica == '' || disabled == 1">
                                        </v-text-field>
                                    </validation-provider>
                                </div>
                            </div>

                            <!-- Campos para el nombre del producto -->
                            <div class="d-flex formulario-flex">
                                <validation-provider v-slot="{ errors }" :rules="{ required: true, }">
                                    <v-text-field outlined id="nombreProducto" v-model="nuevoProducto.nombreProducto"
                                        dense :error-messages="errors" style="width: 35.5rem" label="Nombre" 
                                        @input="convertirAMayusculas('nombreProducto')" :disabled="cambioDedatos || nuevoProducto.nombreProducto == ''">
                                    </v-text-field>
                                </validation-provider>
                            </div>

                            <!-- Campo para elegir la aclaración del producto -->
                            <div class="d-flex formulario-flex">
                                <validation-provider v-slot="{ errors }" :rules="{ required: true, }" ref="aclaraciones">
                                    <v-select outlined dense id="aclaraciones" v-model="nuevoProducto.aclaraciones" 
                                        :menu-props="{offsetY:true, maxHeight:146, maxWidth:568}" style="width: 35.5rem" label="Aclaración"
                                        :error-messages="errors" :items="listaDeAclaraciones" @click="listarAclaraciones()">
                                    </v-select>
                                </validation-provider>
                            </div>

                            <!-- Campo para elegir la restrincción del producto -->
                            <div class="d-flex formulario-flex">
                                <validation-provider v-slot="{ errors }" :rules="{ required: true, }" ref="Restricciones">
                                    <v-select outlined dense id="restricciones" v-model="nuevoProducto.restricciones"
                                        :menu-props="{offsetY:true, maxHeight:120, maxWidth:568}" style="width: 35.5rem" label="Restricción"
                                        :error-messages="errors" :items="listaDeRestricciones" @click="listarRestricciones()">
                                    </v-select>
                                </validation-provider>
                            </div>

                            <!-- Botones de cerrar, guardar y actualizar que se activan cuando se abre el dialogo para el producto-->
                            <div class="d-flex justify-end">
                                <v-btn color="error" text @click="reset()">
                                    CERRAR
                                </v-btn>
                                <v-btn v-if="!editar" depressed @click="crearProducto()" :disabled="validacionActiva || invalid" color="success" text>
                                    {{ textoBoton }}
                                </v-btn>
                                <v-btn v-else depressed @click="actualizarProducto()" :disabled="invalid" color="success" text>
                                    {{ textoBoton }}
                                </v-btn>
                            </div>

                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Tabla que muestra los productos registrados -->
        <v-data-table :loading="loading" fixed-header :headers="headersProductos" :items="listaDeProductos"
            :page.sync="pagina" :items-per-page.sync="itemsPorPagina" :server-items-length="totalPaginas" class="elevation mt-4"
            height="66vh" :footer-props="footerPropsListaContratos">
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.id">
                        <td>{{ item.codigoProducto }}</td>
                        <td>{{ item.descripcionProducto }}</td>
                        <td>{{ item.principioActivo }}</td>
                        <td>{{ item.formaFarmaceutica }}</td>
                        <td>{{ item.concentracion }}</td>
                        <td>
                            <v-icon :color="item.eliminado === false ? 'success' : 'error'"> 
                                {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
                            </v-icon>
                            {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
                        </td>
                        <!-- <td class="text-center">
                            <v-icon color="orange" @click="editarProducto(item)">border_color</v-icon>
                            <v-btn icon @click="abrirDialogoEstado(item)">
                                <v-icon :color="item.eliminado === false ? 'error' : 'success'">
                                    {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
                                </v-icon>
                            </v-btn>
                        </td> -->
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>

        <!-- Campo para activar o inactivar el estado del producto -->
        <v-dialog v-model="dialogoCambiarEstado" persistent transition="dialog-bottom-transition" max-width="22.7rem">
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6"> {{ mensajeEstado }} </span>
                </v-card-title>
                <v-card-text>
                    <div class="d-flex justify-end" style="padding-top: 1.3rem">
                        <v-btn text color="error" @click="dialogoCambiarEstado = false">No</v-btn>
                        <v-btn depressed color="success" text @click="cambiarEstado()">Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-main>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapMutations, mapState } from 'vuex';
import { Role } from '@/router/role.js';
import { required } from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: 'Este campo es requerido'
})

const debounce = function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    const args = arguments;
    const that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export default {
    name: 'Productos',
    components: {
        ValidationObserver,
        ValidationProvider,
    },

    data() {
        return {
            roles: Role,
            userRoles: {},
            usuario: '',
            tipoDeProducto: 1,
            eliminado: false,
            buscadorCodigo: '',
            buscadorNombre: '',
            buscadorPrincipioActivo: '',
            buscadorFormaFarmaceutica: '',
            buscadorConcentracion: '',
            dialog: false,
            editar: false,
            tituloDialog: 'Crear producto',
            textoBoton: "GUARDAR",
            nuevoProducto: {
                codigoProducto: '',
                nombreProducto: '',
                codigoAtc: '',
                concentracion: '',
                principioActivo: '',
                formaFarmaceutica: '',
                aclaraciones: '',
                restricciones: '',
            },
            listaDeAclaraciones: [],
            listaDeRestricciones: [],
            loading: false,
            headersProductos: [
                { text: "CÓDIGO", align: "left", sortable: false, width: "1rem" },
                { text: "NOMBRE", sortable: false, width: "15rem" },
                { text: "PRINCIPIO ACTIVO", align: "left", sortable: false, width: "1rem" },
                { text: "FORMA FARMACÉUTICA", align: "left", sortable: false, width: "1rem" },
                { text: "CONCENTRACIÓN", align: "left", sortable: false, width: "7.4rem" },
                { text: "ESTADO", sortable: false, width: "6.9rem" },
                /* { text: "ACCIONES", align: "center", sortable: false, width: "5.8rem" }, */
            ],
            listaDeProductos: [],
            pagina: 1,
            itemsPorPagina: 10,
            totalPaginas: 0,
            footerPropsListaContratos: {
                'items-per-page-options': [10, 15, 20, 50],
                'items-per-page-text': 'Items por página:',
                'show-current-page': true,
                'show-first-last-page': true,
            },
            dialogoCambiarEstado: false,
            productoSelecionado: {},
            mensajeEstado: '',
            validarExistencia: '',
            validacionActiva: false,
            cambioDedatos: false,
            disabled: 0,
            aclaracionActualizada: null,
            restriccionActualizada: null,
        }
    },

    // Se inicializa la variable userRoles con los roles del usuario autenticado
    created() {
        this.userRoles = this.auth.roles;
        this.listarProductos();
    },

    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"])
    },

    mounted() {
        this.usuario = this.auth.username.toUpperCase();
    },

    watch: {
        pagina: function () {
            this.listarProductos();
        },
        itemsPorPagina: function () {
            this.pagina = 1;
            this.listarProductos();
        },
        'buscadorCodigo': function () {
            this.pagina = 1;
            this.listarProductos();
        },
        'buscadorNombre': function () {
            this.pagina = 1;
            this.listarProductos();
        },
        'buscadorPrincipioActivo': function () {
            this.pagina = 1;
            this.listarProductos();
        },
        'buscadorFormaFarmaceutica': function () {
            this.pagina = 1;
            this.listarProductos();
        },
        'buscadorConcentracion': function () {
            this.pagina = 1;
            this.listarProductos();
        },
        'nuevoProducto.codigoProducto': debounce(function (){
            if (!this.editar) {
                this.validarProducto();
            }
        }, 1500) 
    },

    methods: {
        ...mapMutations([
            "updateAuth",
            "hideNotify",
            "showSuccess",
            "showBusy",
            "hideBusy",
        ]),

        /**
         * Valida que solo se ingresen caracteres númericos en un campo de texto.
         *
         * @param event El evento de teclado que activa esta función.
         * @description Si se presiona una tecla que no sea número, evita que se registre en el campo.
         */
         validarLetraBusqueda(event) {
            const teclaPresionada = event.key
            if (teclaPresionada.match(/[^0-9]/)) {
                event.preventDefault();
            }
        },

        /**
         * Valida que solo se ingresen caracteres númericos en un campo de texto.
         *
         * @param event El evento de teclado que activa esta función.
         * @description Si se presiona una tecla que no sea número, evita que se registre en el campo.
         */
        validarLetra(event) {
            const teclaPresionada = event.key;

            // Verificar si el primer carácter es 0
            if (teclaPresionada === '0' && this.nuevoProducto.codigoProducto === '') {
                event.preventDefault();
            }

            // Verificar si se presiona una tecla que no es un número
            if (teclaPresionada.match(/[^0-9]/)) {
                event.preventDefault();
            }
        },

        /**
         * Convierte el texto en un campo de formulario a mayúsculas.
         *
         * @param campo El nombre del campo en el formulario que se va a convertir a mayúsculas.
         */
        convertirAMayusculas(campo) {
            this.nuevoProducto[campo] = this.nuevoProducto[campo].toUpperCase();
        },

        /**
         * Obtiene la lista de productos mediante una solicitud HTTP GET al servidor.
         * Los productos se obtienen con paginación y se filtran según los parámetros especificados.
         * Se actualiza el número total de páginas.
         * Además, se controla el estado de carga durante la solicitud.
         */
        listarProductos() {
            this.buscadorNombre = this.buscadorNombre.toUpperCase();
            this.buscadorPrincipioActivo = this.buscadorPrincipioActivo.toUpperCase();
            this.buscadorFormaFarmaceutica = this.buscadorFormaFarmaceutica.toUpperCase();
            this.buscadorConcentracion = this.buscadorConcentracion.toUpperCase();
            this.loading = true;
            this.$http.get(`msa-administration/producto/listar`, {
                params: {
                    page: this.pagina - 1,
                    size: this.itemsPorPagina,
                    nombre: `${this.buscadorNombre.toLowerCase()}`,
                    codigoProducto: `${this.buscadorCodigo}`,
                    principioActivo: `${this.buscadorPrincipioActivo.toLowerCase()}`,
                    formaFarmaceutica: `${this.buscadorFormaFarmaceutica.toLowerCase()}`,
                    concentracion: `${this.buscadorConcentracion.toLowerCase()}`,
                    idEmpresa: this.enterprise.code,
                },
            }).then(response => {
                this.listaDeProductos = response.data.content;
                this.totalPaginas = response.data.totalElements;
                this.loading = false;
            }).catch(error => {
                console.log(error)
                this.loading = false;
            })
        },

        /**
         * Recupera una lista de aclaraciones desde el servidor y la almacena en la propiedad
         * 'listaDeAclaraciones' del componente.
         * @throws {Error} Si ocurre un error durante la solicitud al servidor.
         */
        async listarAclaraciones() {
            this.listaDeAclaraciones = [];
            try{
                const response = await this.$http.get(`msa-administration/aclaracion/listaAclaraciones/${this.enterprise.code}`);
                this.listaDeAclaraciones = response.data.map(item => ({
                    text: `${item.idAclaracion} - ${item.descripcionAclaracion}`,
                    value: item.idAclaracion,
                }));
            }catch(error) {
                console.log(error);
            }
        },

        /**
         * Recupera una lista de restricciones desde el servidor y la almacena en la propiedad
         * 'listaDeRestricciones' del componente.
         * @throws {Error} Si ocurre un error durante la solicitud al servidor.
         */
        async listarRestricciones() {
            this.listaDeRestricciones = [];
            try{
                const response = await this.$http.get(`msa-administration/restriccion/listaRestricciones/${this.enterprise.code}`);
                this.listaDeRestricciones = response.data.map(item => ({
                    text: `${item.idRestriccion} - ${item.descripcionRestriccion}`,
                    value: item.idRestriccion,
                }));
            }catch(error) {
                console.log(error);
            }
        },

        /**
         * Valida en Stone.
         * Valida si el producto existe.
         * Realiza una solicitud al servidor para verificar la existencia del producto.
         * Actualiza las propiedades 'validarExistencia' y 'validacionActiva' en respuesta al resultado de la validación.
         */
        validarProducto(){
            if (this.nuevoProducto.codigoProducto !== '') {
                this.$http.get(`msa-administration/inMaite/validarProducto?codProd=${this.nuevoProducto.codigoProducto}&codEmpr=${this.enterprise.code}`)
                .then((response) => {
                    if (response.data !== "El producto existe") {
                        this.validarExistencia = "El producto no existe";
                        this.validacionActiva = true;
                        this.nuevoProducto.codigoAtc = "";
                        this.nuevoProducto.concentracion = "";
                        this.nuevoProducto.principioActivo = "";
                        this.nuevoProducto.formaFarmaceutica = "";
                        this.nuevoProducto.nombreProducto = "";
                    } else {
                        this.validarExistencia = "";
                        this.validacionActiva = false;
                        this.listaDeDetalles();
                        this.validarEstado();
                    }
                }).catch((error) => {
                    console.log(error);
                    this.validarExistencia = "Error en la validación";
                    this.validacionActiva = true;
                })
            } else {
                this.nuevoProducto.codigoAtc = "";
                this.nuevoProducto.concentracion = "";
                this.nuevoProducto.principioActivo = "";
                this.nuevoProducto.formaFarmaceutica = "";
                this.nuevoProducto.nombreProducto = "";
                this.validarExistencia = "";
                this.validacionActiva = false;
            }
        },

        /**
         * Obtiene detalles del producto y actualiza los campos del formulario con la información obtenida.
         * Si algún campo no está disponible o no cumple con ciertos criterios, se establece a 'NO APLICA'.
         * El método maneja la respuesta de la petición al servidor y realiza las actualizaciones correspondientes.
         */
        listaDeDetalles(){
            this.$http.get(`msa-administration/inMaite/listaDeDetalles?codProd=${this.nuevoProducto.codigoProducto}&codEmpr=${this.enterprise.code}`)
            .then((response) => {
                const datos = response.data[0];
                if (datos.codCatm == null || !/^[a-zA-Z0-9\s+]+$/.test(datos.codCatm.trim()) || /^\d+$/.test(datos.codCatm.trim())) {
                    this.nuevoProducto.codigoAtc = 'NO APLICA'
                } else {
                    this.nuevoProducto.codigoAtc = datos.codCatm.trim();
                }
                if (datos.medConc == null || datos.medConc.trim() == '' || datos.medConc.trim() == '.' || datos.medConc.trim().toLowerCase().startsWith('no') || datos.medConc.trim().toLowerCase().startsWith('.no')) {
                    this.nuevoProducto.concentracion = 'NO APLICA'
                } else {
                    this.nuevoProducto.concentracion = datos.medConc.trim();
                }
                if (datos.desCatc == null || datos.desCatc.trim() == '' || datos.codCatm.trim() == '0') {
                    this.nuevoProducto.principioActivo = 'NO APLICA'
                } else {
                    this.nuevoProducto.principioActivo = datos.desCatc.trim();
                } 
                if (datos.forFarm == null || datos.forFarm.trim() == '' || datos.forFarm.trim() == '.' || datos.forFarm.trim().toLowerCase().startsWith('no') || datos.forFarm.trim().toLowerCase().startsWith('.no')) {
                    this.nuevoProducto.formaFarmaceutica = 'NO APLICA'
                } else {
                    this.nuevoProducto.formaFarmaceutica = datos.forFarm.trim();
                }
                this.nuevoProducto.nombreProducto = datos.nomProd.trim();
                this.cambioDedatos = true;
            }).catch((error) => {
                console.log(error);
            })
        },

        /**
         * Valida el estado del producto.
         * Si el código del producto no está vacío, realiza una petición al servidor
         * para verificar si el producto está activo o inactivo.
         * Actualiza la variable de estado 'validarExistencia' y 'validacionActiva'
         * en función de la respuesta del servidor.
         */
        validarEstado(){
            if (this.nuevoProducto.codigoProducto !== '') {
                this.$http.get(`msa-administration/inMaite/estadoDelProducto?codEmpr=${this.enterprise.code}&codProd=${this.nuevoProducto.codigoProducto}`)
                .then((response) => {
                    if (response.data !== "El producto esta activo") {
                        this.validarExistencia = "El producto esta inactivo";
                        this.validacionActiva = true;
                    } else {
                        this.validarExistencia = "";
                        this.validacionActiva = false;
                        this.validarProductoEnPostgres();
                    }
                }).catch((error) => {
                    console.log(error);
                    this.validarExistencia = "Error en la validación";
                    this.validacionActiva = true;
                })
            } else {
                this.validarExistencia = "";
                this.validacionActiva = false;
            }
        },

        /**
         * Valida en PostgreSql.
         * Valida si el producto existe.
         * Realiza una solicitud al servidor para verificar la existencia del producto.
         * Actualiza las propiedades 'validarExistencia' y 'validacionActiva' en respuesta al resultado de la validación.
         */
        validarProductoEnPostgres(){
            if (this.nuevoProducto.codigoProducto !== '') {
                this.$http.get(`msa-administration/producto/validarExistencia?codigoProducto=${this.nuevoProducto.codigoProducto}&idEmpresa=${this.enterprise.code}`)
                .then((response) => {
                    if (response.data !== "El producto no existe") {
                        this.validarExistencia = "El producto ya esta registrado";
                        this.validacionActiva = true;
                    } else {
                        this.validarExistencia = "";
                        this.validacionActiva = false;
                    }
                }).catch((error) => {
                    console.log(error);
                    this.validarExistencia = "Error en la validación";
                    this.validacionActiva = true;
                })
            } else {
                this.validarExistencia = "";
                this.validacionActiva = false;
            }
        },

        /**
         * Guarda la información de un nuevo producto en el servidor.
         * Crea un objeto con los datos del producto, incluyendo la fecha de creación actual,
         * y realiza una solicitud al servidor para registrar el producto. Luego, actualiza la lista
         * de los productos y restablece el formulario.
         */
        crearProducto() {
            const horaActual = new Date;
            const datos = {
                idProducto: null,
                empresa: {
                    idEmpresa: this.enterprise.code,
                },
                codigoProducto: this.nuevoProducto.codigoProducto,
                descripcionProducto: this.nuevoProducto.nombreProducto,
                principioActivo: this.nuevoProducto.principioActivo,
                formaFarmaceutica: this.nuevoProducto.formaFarmaceutica,
                concentracion: this.nuevoProducto.concentracion,
                codigoAtc: this.nuevoProducto.codigoAtc,
                idAclaracion: this.nuevoProducto.aclaraciones,
                idRestriccion: this.nuevoProducto.restricciones,
                fecha: horaActual,
                eliminado: this.eliminado,
                usuario: this.usuario,
                tipoDeProducto: this.tipoDeProducto,
            }
            this.$http
            .post("msa-administration/producto/crear", datos)
            .then(() => {
                this.listarProductos();
                this.reset();
            })
            .catch((error) => {
                console.log(error);
            });
        },

        /**
         * Prepara el formulario para editar un producto seleccionado.
         * Copia los datos del producto y los establece en el formulario para su edición.
         * @param {Object} item - El producto a editar.
         */
        async editarProducto(item) {
            await this.listarAclaraciones();
            await this.listarRestricciones();
            this.productoSelecionado = item;
            this.nuevoProducto.codigoProducto = item.codigoProducto;
            this.nuevoProducto.nombreProducto = item.descripcionProducto;
            this.nuevoProducto.codigoAtc = item.codigoAtc;
            this.nuevoProducto.concentracion = item.concentracion;
            this.nuevoProducto.principioActivo = item.principioActivo;
            this.nuevoProducto.formaFarmaceutica = item.formaFarmaceutica;
            this.nuevoProducto.aclaraciones = this.listaDeAclaraciones.find(idAclaracion => idAclaracion.value === item.aclaracion);
            this.nuevoProducto.restricciones = this.listaDeRestricciones.find(restriccion => restriccion.value === item.restriccion);
            this.tituloDialog = 'Editar producto';
            this.textoBoton = 'ACTUALIZAR';
            this.editar = true;
            this.disabled = 1;
            this.dialog = true;
            this.cambioDedatos = false;
        },

        /**
         * Actualiza la información de un producto.
         * Recopila los datos editados del producto y realiza una petición al servidor para actualizarlos.
         * Después de la actualización, vuelve a cargar la lista de productos y restablece los campos del formulario.
         */
        actualizarProducto() {
            const horaActual = new Date;
            const aclaracion = this.nuevoProducto.aclaraciones.value;
            const aclaracionNueva = this.nuevoProducto.aclaraciones;
            if (aclaracion !== undefined) {
                this.aclaracionActualizada = aclaracion
            } else {
                this.aclaracionActualizada = aclaracionNueva
            }
            const restriccion = this.nuevoProducto.restricciones.value;
            const restriccionNueva = this.nuevoProducto.restricciones;
            if (restriccion !== undefined) {
                this.restriccionActualizada = restriccion
            } else {
                this.restriccionActualizada = restriccionNueva
            }
            const datosEditados = {
                idProducto: this.productoSelecionado.idProducto,
                empresa: {
                    idEmpresa: this.enterprise.code,
                },
                codigoProducto: this.nuevoProducto.codigoProducto,
                descripcionProducto: this.nuevoProducto.nombreProducto,
                principioActivo: this.nuevoProducto.principioActivo,
                formaFarmaceutica: this.nuevoProducto.formaFarmaceutica,
                concentracion: this.nuevoProducto.concentracion,
                codigoAtc: this.nuevoProducto.codigoAtc,
                idAclaracion: this.aclaracionActualizada,
                idRestriccion: this.restriccionActualizada,
                fecha: horaActual,
                eliminado: this.eliminado,
                usuario: this.usuario,
                tipoDeProducto: this.tipoDeProducto,
            }
            this.$http.put("msa-administration/producto/actualizar", datosEditados)
            .then(() => {
                this.listarProductos();
                this.reset();
            }).catch((error) => {
                console.log(error);
            });
        },

        /**
         * Abre el diálogo para cambiar el estado de un producto.
         * Establece la propiedad 'dialogoCambiarEstado' en true para mostrar el diálogo.
         * Guarda el producto seleccionado en la propiedad 'productoSelecionado'.
         * Este método se invoca cuando se desea cambiar el estado de un producto.
         * @param {object} item - El producto seleccionado.
         */
        abrirDialogoEstado(item) {
            this.dialogoCambiarEstado = true;
            this.productoSelecionado = item;
            this.mensajeEstado = this.productoSelecionado.eliminado === false ? '¿Desea inactivar el producto?' : '¿Desea activar el producto?';
            setTimeout(() => {
                this.mensajeEstado = this.productoSelecionado.eliminado === false ? '¿Desea inactivar el producto?' : '¿Desea activar el producto?';
            }, 4000); 
        },

        /**
         * Cambia el estado de un producto.
         * Actualiza el estado del producto seleccionado ('productoSelecionado') según la lógica definida.
         * Realiza una solicitud HTTP PUT al servidor para actualizar el estado del producto.
         * Después de la actualización exitosa, se registra el mensaje y se actualiza la lista de productos.
         */
        cambiarEstado() {
            const horaActual = new Date;
            this.productoSelecionado.eliminado = (this.productoSelecionado.eliminado == false) ? true : false;
            const cambiarEstado = {
                idProducto: this.productoSelecionado.idProducto,
                empresa: {
                    idEmpresa: this.enterprise.code,
                },
                codigoProducto: this.productoSelecionado.codigoProducto,
                fecha: horaActual,
                eliminado: this.productoSelecionado.eliminado,
                usuario: this.usuario,
            }
            this.$http.put(`msa-administration/producto/actualizarEstado`, cambiarEstado)
                .then(() => {
                    this.dialogoCambiarEstado = false;
                    this.listarProductos();
                }).catch(error => {
                    console.log(error);
                })
        },

        /**
         * Restablece los valores de los campos y las variables relacionadas a la creación y edición del producto.
         * Establece los valores predeterminados y cierra los cuadros de diálogo abiertos.
         * Reinicia el estado de edición y activación de campos.
         * Reinicia el estado del componente de observación (refs.observer).
         */
        reset() {
            this.dialog = false;
            this.nuevoProducto.codigoProducto = '';
            this.nuevoProducto.nombreProducto = '';
            this.nuevoProducto.codigoAtc = '';
            this.nuevoProducto.concentracion = '';
            this.nuevoProducto.principioActivo = '';
            this.nuevoProducto.formaFarmaceutica = '';
            this.nuevoProducto.aclaraciones = '';
            this.nuevoProducto.restricciones = '';
            this.listaDeAclaraciones = [];
            this.listaDeRestricciones = [];
            this.tituloDialog = 'Crear Producto';
            this.textoBoton = 'GUARDAR';
            this.validarExistencia = "";
            this.validacionActiva = false;
            this.editar = false;
            this.disabled = 0;
            this.dialogoCambiarEstado = false;
            this.$refs.observer.reset();
        },
    },
}
</script>


<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.fondoDialog {
  background-color: #1867c0;
  color: white;
}

.formulario-flex {
  flex-wrap: nowrap;
}

@media (max-width: 700px) {
  .formulario-flex {
    flex-wrap: wrap !important;
  }
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

::v-deep .elevation .v-data-footer {
  width: 100%;
}

::v-deep .elevation .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}

::v-deep .elevation .v-data-table__wrapper {
  border: 1px solid #f7f6f6;
}
</style>